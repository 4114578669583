@import '@/style/mixins.scss';
@import '@/style/variables.scss';

.studies {
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: #3f1696;
  }

  &__slider {
    padding: 10px 0;
  }

  &__slider-img {
    .swiper-slide {
      opacity: 0;
      max-width: 600px;
      filter: blur(5px);
      transition: transform 0.4s, opacity 0.4s, filter 0.4s;

      @media (max-width: $desk) {
        max-width: 500px;
      }

      @media (max-width: $mDesk) {
        max-width: 400px;
      }

      @media (max-width: $smallDesk) {
        max-width: none;
      }
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      opacity: 1;
    }

    .swiper-slide-active {
      opacity: 1;
      filter: blur(0);
    }
  }

  &__img {
    user-select: none;
    width: 100%;
    transition: filter 0.4s;
  }

  &__slider-info {
    .swiper-button-next,
    .swiper-button-prev {
      background-color: transparent;
      border: none;

      top: 140px;
      margin: 0;

      @media (max-width: $smallDesk) {
        top: 70px;
      }

      @media (max-width: $mobileTablet) {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba($color: #f0e1ff, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        top: auto;
        bottom: 0;

        &::after {
          font-size: 22px;
          font-weight: 700;
        }
      }
    }

    @media (max-width: $mobileTablet) {
      padding-bottom: 70px;

      .swiper-button-prev {
        left: 50%;
        transform: translateX(-140%);
      }

      .swiper-button-next {
        right: 50%;
        transform: translateX(140%);
      }
    }
  }

  &__info {
    @include flex(flex, center, center, column, 30px);
    max-width: 1000px;
    margin: 50px auto 0;
    text-align: center;

    @media (max-width: $mDesk) {
      max-width: 80%;
    }

    @media (max-width: $smallDesk) {
      margin: 0 auto;
    }

    @media (max-width: $mobileTablet) {
      max-width: none;
    }
  }

  &__info-title {
    font-size: 36px;
    line-height: 1.2;

    @media (max-width: $smallDesk) {
      font-size: 20px;
    }
  }

  &__btn {
    border: 1px solid $color-accent;
    color: darken($color: $color-accent, $amount: 5%);
    svg path {
      stroke: darken($color: $color-accent, $amount: 5%);
    }
  }
}
