@import '@/style/variables.scss';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media (max-width: $mDesk) {
    display: none;
  }

  &--dark {
    color: #fff;

    .breadcrumbs__item:last-child .breadcrumbs__link {
      color: $orange;
    }

    .breadcrumbs__link:before {
      background-color: #fff;
    }
  }

  &--light {
    color: $black;

    .breadcrumbs__item:last-child .breadcrumbs__link {
      color: $color-accent;
    }

    .breadcrumbs__link:before {
      background-color: $black;
    }
  }

  &__item {
    & + .breadcrumbs__item {
      margin-left: 10px;

      &::before {
        content: '>';
        margin-right: 10px;
        font-size: 18px;
        line-height: 0;
      }
    }

    &:last-child .breadcrumbs__link {
      color: $orange;

      &::before {
        display: none;
      }
    }
  }

  &__link {
    text-transform: capitalize;
    font-size: 14px;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -3px;
      left: 0;
      background: #fff;
      transition: width 0.4s, left 0.4s;
    }
    &:hover {
      &:before {
        width: 0;
        left: 50%;
      }
    }
  }
}
