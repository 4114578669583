@import '@/style/variables.scss';

.blog {
  &__info {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.6;

    @media (max-width: $mDesk) {
      font-size: 15px;
      line-height: 1.2;
    }
  }

  &__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
    overflow: hidden;

    img {
      border-radius: 50%;
      object-fit: cover;
      transition: transform .4s;
      will-change: transform;
    }

    &:hover img{
      transform: scale(1.1);
    }

    @media (max-width: $mDesk) {
      width: 50px;
      height: 50px;
    }
  }

  &__name {
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  &__date {
    margin-right: 5px;
  }
}
