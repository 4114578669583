@import '@/style/variables.scss';

.industry-card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 463px;
  min-height: 321px;
  color: $white;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
  @media (max-width: $largeDesk) {
    min-height: 400px;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 0.5s;
    background-color: var(--background-color);
    transform: translateZ(0); // Fix artefacts, because use scale in hover
    img {
      width: 100%;
      mask-image: linear-gradient(to bottom, black 75%, transparent 100%);
    }
  }

  &:hover &__img {
    transform: scale(1.15);
  }

  &__content {
    padding: 23px 32px 0;
    svg {
      margin-bottom: 10px;
      path {
        fill: $white;
      }
    }
    h3 {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }

  &__index {
    font-feature-settings: normal;
    font-size: 65px;
    font-weight: 700;
    opacity: 0.3;
    display: block;
    margin: -20px 0 0 -3px;
  }

  button {
    margin: auto 32px 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: $white;
    background: 0 0;
    border: none;
    display: flex;
    width: 197px;
    height: 32px;
    gap: 18px;

    svg {
      height: 32px;
      transition: 0.5s;
      path {
        stroke: $white;
      }
    }
  }
  &:hover button svg {
    transform: translateX(15px);
  }
}

.industry-card:nth-child(n + 5):nth-child(-n + 8) {
  @media (max-width: $smallDesk) {
    height: 422px;
  }

  picture img {
    @media (max-width: $smallDesk) {
      width: 602px;
      height: 422px;
    }
  }
}
