.lazyImage_animated {
  transition: opacity 350ms ease-out;
  opacity: 0;

  // TODO Add box-shadow: inset 0 0 12px 0 #f2f2f2;

  &[data-loaded] {
    opacity: 1;
  }
}
