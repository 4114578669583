@import '@/style/mixins.scss';
@import '@/style/variables.scss';

.ready-to-launch {
  padding: 180px 0 50px;
  position: relative;
  background-color: $black;

  @media (max-width: $mDesk) {
    padding-top: 110px;
  }

  @media (max-width: $smallDesk) {
    padding: 70px 0;
    background-color: $black;

    &::before {
      content: '';
      width: 300px;
      height: 300px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(165, 74, 255, 0.43) 0%,
        rgba(165, 74, 255, 0) 100%
      );
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__slogan {
    font-size: 200px;
    color: $color-accent;
    text-align: right;
    font-weight: 700;
    line-height: 1.1;
    opacity: 0.1;
    text-transform: uppercase;
    letter-spacing: 10px;
    position: absolute;
    right: 0;
    top: -110px;
    pointer-events: none;
    user-select: none;
    z-index: 0;

    @media (max-width: $smallDesk) {
      display: none;
    }
  }

  &__gradient-1 {
    width: 900px;
    height: 900px;
    background: radial-gradient(50% 50% at 50% 50%, #f96e20 0%, rgba(249, 110, 32, 0) 100%);
    opacity: 0.3;
    position: absolute;
    top: -150px;
    right: 250px;
    z-index: 0;
    pointer-events: none; // Because this gradient live outside container and prevents interaction on other elements
  }

  &__gradient-2 {
    width: 550px;
    height: 550px;
    background: radial-gradient(50% 50% at 50% 50%, #f96e20 0%, rgba(249, 110, 32, 0) 100%);
    opacity: 0.3;
    position: absolute;
    top: -180px;
    right: 0;
    z-index: 0;
    pointer-events: none; // Because this gradient live outside container and prevents interaction on other elements
  }

  &__gradient-3 {
    width: 1100px;
    height: 1100px;
    background: radial-gradient(50% 50% at 50% 50%, #ac59ff 0%, rgba(165, 74, 255, 0) 100%);
    opacity: 0.3;
    position: absolute;
    top: -200px;
    right: -700px;
    z-index: 0;
    pointer-events: none; // Because this gradient live outside container and prevents interaction on other elements
  }

  &__bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $smallDesk) {
      display: none;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 52% 48%;
    padding-left: 100px;
    position: relative;

    &:hover {
      .ready-to-launch__lamp::after {
        opacity: 1;
      }
    }

    @media (max-width: $desk) {
      padding-left: 15px;
    }

    @media (max-width: $smallDesk) {
      grid-template-columns: 1fr;
      position: static;
    }
  }

  &__content {
    z-index: 2;
    @media (max-width: $smallDesk) {
      @include flex(flex, center, center, column);
      text-align: center;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 80px;
    max-width: 880px;
    color: #fff;
    line-height: 1.1;
    text-align: left;
    margin-bottom: 20px;

    span {
      display: block;
      color: $color-accent;
    }

    @media (max-width: $mDesk) {
      font-size: 70px;
    }

    @media (max-width: $smallDesk) {
      text-align: center;
      font-size: 45px;
      margin-bottom: 30px;
    }
  }

  &__text {
    font-size: 30px;
    color: #fff;
    max-width: 700px;
    margin-bottom: 50px;

    @media (max-width: $smallDesk) {
      font-size: 20px;
      margin-bottom: 35px;
    }
  }

  &__btn {
    @media (max-width: $mobileTablet) {
      width: auto;
      min-width: 270px;
    }
  }

  &__cosmo {
    margin: -50px 0 -100px 75px;

    @media (max-width: $smallDesk) {
      margin: 0;
    }
  }

  &__laptop {
    margin: -70px 0 -150px 75px;

    @media (max-width: $mDesk) {
      margin: 0px 0 0px 45px;
    }

    @media (max-width: $smallDesk) {
      margin: 0;
    }
  }

  &__lamp {
    margin: -70px 0 -140px 105px;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 80%;
      background: radial-gradient(50% 50% at 50% 50%, #ac59ff 0, rgba(165, 74, 255, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 1s;
    }

    @media (max-width: $smallDesk) {
      margin: 0;

      &::after {
        display: none;
      }
    }
  }

  &__apple {
    margin: -100px 0 -140px 105px;

    @media (max-width: $mDesk) {
      margin: -40px 0 -90px 45px;
    }

    @media (max-width: $smallDesk) {
      margin: 0;
    }
  }

  &__img {
    z-index: 1;

    img {
      // height: 100%;
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: contain;
    }

    @media (max-width: $smallDesk) {
      height: 90%;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.2;
      z-index: 1;
    }
  }
}
