@import '@/style/variables.scss';

.blog__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px 30px;

  @media (max-width: $mDesk) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }

  @media (max-width: $iPadMini) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
