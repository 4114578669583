@import '@/style/variables.scss';

.intro {
  display: flex;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
  padding: 150px 0;

  position: relative;
  z-index: 0;

  &__bgImage {
    position: absolute;
    top: -1px; // Hide one pixel white artifact when browser interpolating corer.
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }

  @media (max-width: $extraLargeDesk) {
    min-height: 100vh;
  }

  @media (max-width: $smallDesk) {
    min-height: auto;
    padding: 90px 0 70px;
    text-align: center;
  }

  &__inner {
    color: $white;
    width: 100%;
    margin: auto;
  }

  &__title {
    font-weight: 700;
    font-size: 80px;
    line-height: 1.2;
    margin-bottom: 25px;
    max-width: 880px;

    span {
      color: $color-accent;
    }

    @media (max-width: $smallDesk) {
      font-size: 45px;
    }
  }

  &__text {
    font-size: 30px;
    line-height: 1.3;
    max-width: 750px;

    & + .intro__text {
      margin-top: 35px;
    }

    @media (max-width: $smallDesk) {
      font-size: 20px;
    }
  }

  &__btn {
    margin-top: 40px;

    @media (max-width: $smallDesk) {
      margin: 20px auto 0;
    }
  }

  &--home {
    position: relative;
    background-color: #000;
    overflow: hidden;

    .intro__bgImage {
      opacity: 0.5;
    }

    &::before {
      content: '';
      width: 800px;
      height: 800px;
      background: radial-gradient(50% 50% at 50% 50%, #ac59ff 0%, rgba(165, 74, 255, 0) 100%);
      opacity: 0.3;
      position: absolute;
      right: -200px;
      bottom: -28%;
      z-index: 0;
      pointer-events: none;

      @media (max-width: $smallDesk) {
        display: none;
      }
    }
    .intro__inner {
      position: relative;

      &::before {
        content: '';
        width: 800px;
        height: 800px;
        background: radial-gradient(50% 50% at 50% 50%, #f96e20 0%, rgba(249, 110, 32, 0) 100%);
        opacity: 0.2;
        position: absolute;
        left: -30px;
        top: -360px;
        pointer-events: none;
        z-index: 0;

        @media (max-width: $smallDesk) {
          display: none;
        }
      }

      &::after {
        content: '';
        width: 900px;
        height: 900px;
        background: radial-gradient(50% 50% at 50% 50%, #f96e20 0%, rgba(249, 110, 32, 0) 100%);
        opacity: 0.3;
        position: absolute;
        left: -210px;
        bottom: -170%;
        pointer-events: none;
        z-index: 0;

        @media (max-width: $smallDesk) {
          display: none;
        }
      }
    }
  }

  &--reactjs {
    .intro__text {
      max-width: 850px;
    }
  }

  &--devOps {
    .intro__bgImage_default {
      display: none;
    }
    .intro__bgImage_large {
      display: block;
    }

    .intro__title {
      max-width: 800px;
    }

    @media (max-width: $extraLargeDesk) {
      .intro__bgImage_default {
        display: block;
      }
      .intro__bgImage_large {
        display: none;
      }
    }
  }

  &--qa {
    .intro__title {
      max-width: 690px;
    }
  }

  &--company {
    .intro__text {
      max-width: 1000px;
    }
  }
}
