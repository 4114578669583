.lazyImageWithPlaceholder2 {
  position: relative;
  z-index: 0;
  // height: 100%;
  // width: 100%;

  // filter: blur(8px);
  transition: filter 350ms ease-out;

  &[data-loaded] {
    // filter: blur(0);
  }

  // https://stackoverflow.com/questions/28870932/how-to-remove-white-border-from-blur-background-image
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: cover;
  }


  &__img {
    transition: opacity 350ms linear, transform 700ms ease-out;
    opacity: 0;
    transform: scale(1.008);
  }

  &[data-loaded] &__img {
    opacity: 1;
    transform: scale(1);
  }

  &__placeholder {
    height: 100%;
    width: 100%;
    object-fit: cover;
    // transition: opacity 700ms cubic-bezier(.99,.03,.97,.27); // Bad effect
    transition: opacity 700ms cubic-bezier(.8,.08,.68,.51);
    opacity: 1;
  }

  &_longAnimation &__img {
    transition: opacity 700ms linear, transform 1400ms ease-out;
  }

  &_longAnimation &__placeholder {
    transition: opacity 1400ms cubic-bezier(.8,.08,.68,.51);
  }

  &_removePlaceholderOnLoad &__img {
    // transition: opacity 700ms linear, transform 1400ms ease-out;
    // opacity: 0;
    transform: scale(0.992);
    // transform: scale(0.992);
    // transform: scale(1.008);
  }

  &_removePlaceholderOnLoad[data-loaded] &__placeholder {
    opacity: 0;
  }

  &_invisiblePlaceholder &__placeholder {
    opacity: 0;
  }
}
