@import '@/style/mixins.scss';
@import '@/style/variables.scss';

.services-card {
  @include flex(flex, space-between, flex-start, column);
  transition: 0.2s;
  padding: 30px;
  position: relative;
  color: #140612;
  cursor: pointer;
  transition: 0.7s;

  @media (max-width: $iPadMini) {
    padding: 30px 15px;
  }

  svg {
    margin-bottom: 20px;
    height: 68px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  button {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: $color-secondary;
    background: 0 0;
    border: none;
    display: flex;
    width: 197px;
    height: 32px;
    gap: 18px;
    svg {
      height: 32px;
      path {
        stroke: $color-secondary;
      }
      transition: 0.5s;
    }
  }
  &:hover button svg {
    transform: translateX(15px);
  }

  &:hover {
    box-shadow: 0 4px 30px rgb(139 88 190 / 20%);
    border-radius: 10px;
    background: #fff;
    cursor: pointer;

    &::before {
      opacity: 0.3;
      content: '';
      display: block;
      width: 282px;
      height: 265px;
      background: radial-gradient(50% 50% at 50% 50%, #ac59ff 0, rgba(165, 74, 255, 0) 100%);
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.5s;
    }
  }
}
