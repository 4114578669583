.lazyImageWithPlaceholder {
  // position: relative;
  // z-index: 0;
  // height: 100%;
  // width: 100%;

  &__placeholder,
  &__noScriptImage,
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: cover;
  }

  &__img {
    transition: opacity 150ms linear;
    opacity: 0;
  }

  [data-loaded] &__img {
    opacity: 1;
  }
}
