@import '@/style/variables.scss';

.blog {
  &__item {
    display: block;
    height: 100%;
    padding: 30px;
    border-radius: 10px;
    transition: box-shadow 0.4s;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 30px rgba(139, 88, 190, 0.2);
    }

    @media (max-width: $smallDesk) {
      padding: 15px;
    }

    @media (max-width: $iPadMini) {
      box-shadow: 0px 4px 30px rgba(139, 88, 190, 0.2);
    }
  }

  &__img {
    height: 225px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 24px;
    object-fit: cover;

    @media (max-width: $iPadMini) {
      margin-bottom: 10px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 20px 0;

    @media (max-width: $smallDesk) {
      font-size: 20px;
      margin: 10px 0;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    line-height: 1.4;

    @media (max-width: $smallDesk) {
      font-size: 14px;
    }
  }

  &__tags-item {
    &:not(:last-child) {
      position: relative;
      padding-right: 14px;
      margin-right: 5px;

      &::after {
        content: '/';
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
