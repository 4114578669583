.animation-card-top {
  transform: translate(0px, 30%);
  transition: all 0.3s ease 0s;
  opacity: 1;
  &--hidden {
    opacity: 0;
  }
  &--active {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
@for $varm from 1 to 4 {
  @for $var from 1 to 4 {
    $delay: $varm * 0.1;
    $count: (3 * $var) - (3 - $varm);
    .animation-card-top:nth-child(#{$count}) {
      transition: all 1s ease #{$delay + s};
    }
  }
}

.animation-rotation-counterclock {
  &--active {
    transition: all 1.5s ease-in-out;
    transform: rotate(-360deg);
  }
}
